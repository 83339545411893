import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import moduleConfig from 'moduleConfig';

Sentry.init({
	dsn: moduleConfig.sentry.dsn,
	environment: moduleConfig.sentry.environment,
	integrations: [
	  new Sentry.BrowserTracing(),
	  new Sentry.Replay(),
	  new CaptureConsole({ levels: [ 'error', 'assert' ] }),
	],
	// Performance Monitoring
	tracesSampleRate: moduleConfig.sentry.tracesSampleRate,
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		moduleConfig.api.hostUrl,
		moduleConfig.api.webResourcePath,
		moduleConfig.auth.loginUrl,
	],
});

export function traceResgateOperation(name, operation) {
	if (moduleConfig.sentry.dsn) {
		return Sentry.startSpan({ name, op: "resgate" }, operation);
	} else {
		return operation();
	}
}
