import ResClient, { isResError } from 'resclient';
import { traceResgateOperation } from 'sentry';
import Err from 'classes/Err';

const namespace = 'module.api';

/**
 * Api module connects to the backend api and provides low level
 * methods for service modules to send and receive data.
 */
class Api extends ResClient {

	constructor(app, params) {
		let opt = Object.assign({
			hostUrl: '/ws',
			webResourcePath: '/api/',
			debug: false,
		}, params);
		opt.debug = !!(opt.debug && opt.debug != 'false' && opt.debug != '0' && opt.debug != 'no');
		super(opt.hostUrl, {
			namespace,
			eventBus: app.eventBus,
			debug: opt.debug,
		});

		this.app = app;
		this.webResourcePath = this._resolveWebResourcePath(opt.webResourcePath);
	}

	getWebResourceUri(rid) {
		let idx = rid.indexOf('?');
		let rname = idx >= 0 ? rid.substr(0, idx) : rid;
		let query = idx >= 0 ? rid.substr(idx) : '';

		return this.webResourcePath + rname.replace(/\./g, '/') + query;
	}

	isError(resource) {
		if (isResError(resource)) {
			return true;
		}
		return resource instanceof Error || resource instanceof Err || (
			typeof resource == 'object'
			&& resource !== null
			&& resource.hasOwnProperty('code')
			&& resource.hasOwnProperty('message'));
	}

	_resolveWebResourcePath(url) {
		if (!url.match(/^http?:\/\//)) {
			let a = document.createElement('a');
			a.href = url;
			url = a.href;
		}

		return url.replace(/\/$/, '') + '/';
	}

	dispose() {
		this.disconnect();
	}

	// Tracing overrides

	get(rid) {
		return traceResgateOperation(`get ${rid}`, () => super.get(rid));
	}

	setModel(rid, props) {
		return traceResgateOperation(`setModel ${rid}`, () => super.setModel(rid, props));
	}

	call(rid, method, params) {
		return traceResgateOperation(`call ${rid}/${method}`, () => super.call(rid, method, params));
	}

	authenticate(rid, method, params) {
		return traceResgateOperation(`authenticate ${rid}/${method}`, () => super.authenticate(rid, method, params));
	}
}

export default Api;
